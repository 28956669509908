<template>
  <v-card>
    <v-container>
      <v-row dense justify="center">
        <v-col cols="12">
          <!-- Select contract model -->
          <Model
            modelType="rentIncrease"
            ref="model"
            :isValidContract="newRentIncrease.modelId !== ''"
            @contractmodelidchanged="contractModelIdChangeHandler"
            noDataText="Luo ensin vuokrakorotuspohja sopimukset valikosta"
          />

          <!-- CONTENT MODE -->
          <div v-if="!editmode && newRentIncrease.modelId && showEditMode">
            <v-btn small class="mb-2 mt-2" color="primary" @click="editContent">
              Muokkaa viestiä
            </v-btn>

            <div class="editor-wrapper">
              <div v-html="contractContent" />
            </div>

            <v-btn small class="mt-2 mb-2" color="primary" @click="editContent">
              Muokkaa viestiä
            </v-btn>
          </div>

          <!-- EDIT MODE -->
          <div v-if="editmode" class="editor-wrapper mt-2">
            <ModelEditor
              v-if="editmode"
              @cancelcontenthandler="contentCancelHandler"
              @savecontent="contentOkHandler"
              modelType="rentIncrease"
              :value="modelContentLoaded"
              @input="handlerContentChange"
            />
          </div>
        </v-col>
      </v-row>

      <v-divider class="mb-2 mx-2"></v-divider>

      <div v-if="contractContent !== ''">
        <h2>Yhteenveto</h2>

        <p class="success--text mb-1">Vuokrankorotus on valmis tallennattavaksi.</p>

        <p class="mb-1">
          Korotetun laskun eräpäivä on
          <strong>{{ formatDate(newRentIncrease.dueDate) }}</strong> ja lähetyspäivä on
          <strong> {{ formatDate(billDate) }}</strong
          >. Et voi hyväksyttyäsi enää aikaistaa korotetun laskun eräpäivän kuukautta.
        </p>

        <p v-if="dontSendMessage">
          Viestiä ei lähetetä asiakkaalle, mutta voit tulostaa viestin pdf-muodossa tallennuksen
          jälkeen.
        </p>
        <p v-else>Voit tulostaa viestin myös pdf-muodossa sähköpostilähetyksen lisäksi.</p>

        <v-form ref="form" class="mt-1">
          <v-row dense>
            <v-col cols="12" sm="5" md="4" lg="3">
              <v-checkbox
                style="margin-top: 0px"
                v-model="dontSendMessage"
                dense
                label="Älä lähetä viestiä"
                hide-details
                small
                @change="
                  (val) => (val ? (replyToEmail = null) : getReplyToEmailAddress(replyToSelector))
                "
              ></v-checkbox>
            </v-col>

            <v-col v-if="!dontSendMessage" cols="12" sm="5" md="4" lg="3">
              <v-select
                v-model="replyToSelector"
                :items="replyToItems"
                item-value="val"
                item-text="text"
                outlined
                dense
                label="Viestin vastausosoite"
                hide-details
                @change="getReplyToEmailAddress"
              >
              </v-select>
            </v-col>

            <v-col
              v-if="!dontSendMessage && replyToSelector != 'noReply'"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-text-field
                v-model="replyToEmail"
                label="Reply-to email"
                :rules="validations.required.concat(validations.email)"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <div v-if="!dontSendMessage" class="mt-1">
          <!-- No emails found -->
          <p v-if="tenantsWithoutEmail.length > 0">
            <span
              >Seuraavien vuokralaisten emaileja ei löytynyt:
              <span v-for="(tenant, index) in tenantsWithoutEmail" :key="'a' + index">
                <router-link style="text-decoration: none" :to="`/tenant/edit/${tenant._id}`">
                  <span>{{ tenant.name }}</span
                  ><span v-if="index < tenantsWithoutEmail.length - 1">, </span>
                </router-link></span
              >
            </span>
            <span>
              <v-tooltip bottom max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1"
                    :color="messageReceivers.length == 0 ? 'error' : 'warning'"
                    >mdi-alert-circle</v-icon
                  >
                </template>
                <span>{{
                  messageReceivers.length == 0
                    ? "Lisää linkistä ainakin yhdelle vuokralaiselle email"
                    : "Voit lisätä muidenkin vastaanottajien emailit vuokralaisen linkistä"
                }}</span>
              </v-tooltip>
            </span>
          </p>

          <div v-if="messageReceivers.length > 0">
            <p class="mb-1">
              Kun tallennat ja lähetät, ylläoleva sähköposti lähetetään seuraaville vuokralaisille:
            </p>
            <p v-for="(tenant, index) in messageReceivers" :key="'b' + index">
              <strong>{{ tenant.name }} ({{ tenant.email }})</strong>
            </p>
          </div>

          <p class="mt-1">
            Vastausosoite emailiin on
            <strong>{{
              replyToSelector != "noReply" ? replyToEmail : "no-reply@vuokranet.fi"
            }}</strong>

            <v-icon
              class="ml-1"
              medium
              :color="validations.isValidReplyToEmail(replyToEmail) ? 'success' : 'error'"
              >{{
                validations.isValidReplyToEmail(replyToEmail)
                  ? "mdi-check-circle"
                  : "mdi-alert-circle"
              }}
            </v-icon>
          </p>
        </div>

        <!-- PRICES -->
        <v-row class="mt-2" dense>
          <v-col cols="12" sm="5">
            <!-- Prices -->
            <Prices productType="currentProducts"></Prices>
          </v-col>

          <v-col cols="12" sm="2">
            <div class="arrow-box">
              <v-icon large color="primary">mdi-arrow-right-thick</v-icon>
            </div>
          </v-col>

          <v-col cols="12" sm="5">
            <!-- Prices -->
            <Prices productType="rentIncreaseProducts"></Prices>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Model from "../ContractModels/Model.vue";
import ModelEditor from "../ContractModels/ModelEditor.vue";
import Prices from "./Prices.vue";
import validations from "@/validations";
import helpers from "@/utils/rentIncreaseHelpers";
import { mapState, mapMutations, mapActions } from "vuex";
import mixins from "../../mixins/mixins";
import moment from "moment";

export default {
  mixins: [mixins],

  components: {
    Model,
    ModelEditor,
    Prices,
  },

  data() {
    return {
      modelContentLoaded: "",
      modelContentMutated: "",
      showEditMode: false,
      editmode: false,
      replyToItems: [
        { text: "Käyttäjän email", val: "user" },
        { text: "Tilin omistajan email", val: "account" },
        { text: "Vuokranet no-reply", val: "noReply" },
      ],
      validations,
    };
  },

  created() {
    this.$root.$refs.rentIncreaseMessage = this;
  },

  computed: {
    ...mapState("rentIncrease", ["currentContract", "newRentIncrease"]),
    ...mapState("contractmodel", ["contractModel", "contractContent"]),
    ...mapState("account", ["currentUser"]),

    dontSendMessage: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.dontSendMessage;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "dontSendMessage" });
      },
    },

    replyToSelector: {
      get() {
        return this.$store.state.rentIncrease.replyToSelector;
      },
      set(val) {
        this.setReplyToSelector(val);
      },
    },

    replyToEmail: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.replyToEmail;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "replyToEmail" });
      },
    },

    messageReceivers() {
      let tenants = [];
      // Tenant
      if (this.currentContract?.tenant?.tenantId?.email) {
        tenants.push({
          email: this.currentContract.tenant.tenantId.email,
          name: this.currentContract.tenant.tenantId.name,
        });
      }
      // Other tenants
      this.currentContract.otherTenants.forEach((el) => {
        if (el.tenantId?.email) tenants.push({ email: el.tenantId.email, name: el.tenantId.name });
      });
      return tenants;
    },

    tenantsWithoutEmail() {
      let tenants = [];
      if (!this.currentContract?.tenant?.tenantId?.email) {
        tenants.push(this.currentContract.tenant.tenantId);
      }
      // Other tenants
      this.currentContract.otherTenants.forEach((el) => {
        if (!el.tenantId?.email) tenants.push(el.tenantId);
      });
      return tenants;
    },

    billDate() {
      if (this.newRentIncrease.dueDate) {
        let dueDate = moment(this.newRentIncrease.dueDate);

        const nextBillDate = dueDate
          .subtract(this.newRentIncrease.paymentTerm, "days")
          .format("YYYY-MM-DD");

        if (Date.now() > new Date(nextBillDate).getTime()) {
          return moment(Date.now() + 24 * 60 * 60 * 1000).format("YYYY-MM-DD");
        } else return nextBillDate;
      } else {
        return "";
      }
    },
  },

  watch: {
    contractModel() {
      this.modelContentLoaded = this.contractModel.content;
      this.modelContentMutated = this.modelContentLoaded;
      this.setNewRentIncreaseValue({ val: this.modelContentMutated, field: "content" });
      this.getContent();
    },
  },

  methods: {
    ...mapActions("contractmodel", ["getContractModel", "getContractContent"]),
    ...mapMutations("rentIncrease", ["setNewRentIncreaseValue", "setReplyToSelector"]),

    // when going to step 3
    getAllContent() {
      this.modelContentLoaded = this.newRentIncrease.content || this.modelContentLoaded;
      this.modelContentMutated = this.modelContentLoaded;
      this.getContent();
    },

    handlerContentChange(content) {
      this.modelContentMutated = content;
    },

    contractModelIdChangeHandler() {
      this.getContractModel(this.newRentIncrease.modelId);
    },

    editContent() {
      this.editmode = true;
    },

    contentOkHandler() {
      this.modelContentLoaded = this.modelContentMutated;
      this.setNewRentIncreaseValue({ val: this.modelContentMutated, field: "content" });
      this.getContent();

      this.editmode = false;
    },

    contentCancelHandler() {
      this.modelContentMutated = this.modelContentLoaded;
      this.editmode = false;
    },

    getContent() {
      this.getContractContent({
        rentalContractId: this.currentContract._id,
        contractData: this.newRentIncrease,
        contentData: this.modelContentMutated,
        contractType: "rentIncrease",
      });

      // Set content to contract
      this.setNewRentIncreaseValue({ val: this.modelContentMutated, field: "content" });

      // Show edit buttons and contract
      if (this.newRentIncrease.modelId) this.showEditMode = true;
    },

    getReplyToEmailAddress(val) {
      this.replyToEmail = helpers.getReplyToEmail(val);
    },

    validateForm() {
      const formValidated = this.$refs.form.validate();
      if (this.dontSendMessage) {
        return formValidated;
      } else {
        return formValidated && this.messageReceivers.length > 0;
      }
    },

    resetValues() {
      this.replyToEmail = null;
    },
  },
};
</script>

<style scoped>
.editor-wrapper {
  border: 1px solid rgb(193, 193, 193);
  border-radius: 4px;
  padding: 15px;
}

div >>> h1 {
  font-size: 1.8em;
  line-height: 1.4em;
  font-weight: 600;
  margin-bottom: 15px;
}

div >>> h2 {
  line-height: 1.3em;
  margin-bottom: 10px;
}

div >>> h3 {
  line-height: 1.3em;
  margin-bottom: 5px;
}

div >>> .text-center {
  text-align: center;
}

div >>> p {
  line-height: 1.35em;
}

div >>> p:empty {
  height: 0.5em;
}

div >>> .grid-container {
  grid-template-columns: auto auto;
  display: grid;
  max-width: 800px;
}

div >>> .grid-item {
  margin-right: 50px;
  text-align: left;
  max-width: 350px;
}

@media only screen and (max-width: 800px) {
  div >>> .grid-container {
    grid-template-columns: auto;
  }
  div >>> .grid-item {
    margin-right: 10px;
  }
  div >>> p {
    font-size: 12px;
  }
  div >>> h1 {
    font-size: 1.35em;
  }
  div >>> h2 {
    font-size: 1.2em;
  }
  div >>> h3 {
    font-size: 1em;
  }
  div >>> .variable {
    font-size: 12px;
  }
}

.arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
